import "react-sortable-tree/style.css";
import "./public/css/main.css";
import '../node_modules/react-sortable-tree/style.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { routes } from "./routes";
import { Helmet } from 'react-helmet';
import { Login } from "./RouteConstant";
import { createContext, useState, useContext, useReducer } from "react";
import { useEffect } from "react";
import { UserToken, getUserDetail, getSiteManagement, getSiteDetail } from "./Services/Service";
import { ActionType, reducer } from "./Reducer";
import { Layout } from "./Components/Layout";
import PermissionCheckHoc from "./Components/PermissionCheckHoc";
import FAVICON from "./assets/default-favicon.png";
import { onFailure } from "./util/helper";

function AuthGuardedRoute(state: any) {
  const user = state?.user;
  
  return function ({ component: Component, ...rest }: any) {
    // console.log("rest", rest);
    return (
      <Route
        {...rest}
        render={(props) => {
          return !!user ? (
            <PermissionCheckHoc {...rest.props}>
              <Component {...props} {...rest.props} />
            </PermissionCheckHoc>
          ) : (
            <Redirect to={Login} />
          );
        }}
      />
    );
  };
}

const initialState = {
  state: {
    page_loading: true,
  },
  dispatch: () => {},
};

export const AppContext = createContext<{
  state: any;
  dispatch: any;
}>(initialState);

function Main() {
  const { state, dispatch } = useContext<any>(AppContext);

  const onUserSuccess = ({ data }: any) => {
    dispatch({
      type: ActionType.GET_USER_SUCCESS,
      payload: data,
    });
  };

  const userFailure = (resp: any) => {
    console.log(resp);
    dispatch({
      type: ActionType.SET_LOADING_FLAG,
      payload: false,
    });
  };

  useEffect(() => {
    if (UserToken()) {
      getUserDetail(onUserSuccess, userFailure);
    } else {
      dispatch({
        type: ActionType.SET_LOADING_FLAG,
        payload: false,
      });
    }
  }, []);

  const AdminRoute = AuthGuardedRoute(state);
  return !state?.page_loading ? (
    <Router>
      <Switch>
        {routes.map((route) =>
          route.admin ? (
            <Layout key={route.key}>
              {routes.map((route) => (
                <AdminRoute
                  path={route.path}
                  exact
                  key={route.key}
                  component={route.component}
                  props={route.props}
                ></AdminRoute>
              ))}
            </Layout>
          ) : (
            <Route
              path={route.path}
              key={route.key}
              component={route.component}
            ></Route>
          )
        )}
      </Switch>
    </Router>
  ) : (
    <p>Loading</p>
  );
}

//Need to use context provider for state management
function App() {
  // const [state, setState] = useState<any>();
  const [state, dispatch] = useReducer(reducer, initialState.state);
  const [favicon, setFavicon] = useState<any>(FAVICON);
  const [title, setTitle] = useState<any>("Blog");
  useEffect(()=>{
    getSiteDetail(onGetSuccess,onFailure)
  })
  const onGetSuccess = (res:any)=>{
    setFavicon(res.data?.siteFavicon?.url)
    setTitle(res.data.seo.title)
    localStorage.setItem("_id",res.data._id)
  }
  return (
    <>
    <div>
      <Helmet>
      <title>{title}</title>
        <link rel="icon" type="image/png" href={favicon}/>
      </Helmet>
      {/* rest of your app */}
    </div>
    <AppContext.Provider value={{ state, dispatch }}>
      <Main />
    </AppContext.Provider>
    </>
  );
}

export default App;
